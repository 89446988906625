<template>
  <div>
    <a-card title="采购入库">
      <a-row :gutter="16">
        <a-col :span="24" :md="8" :xl="6" style="max-width: 256px; margin-bottom: 12px;">
          <a-input-search v-model="searchForm.search" placeholder="单号,供应商编号/名称" allowClear @search="search" />
        </a-col>
        <a-col :span="4"> <a-button type="primary" @click="onExport">导出</a-button></a-col>


        <a-col :span="5" style=" margin-bottom: 12px">
          <a-row type="flex">
            <a-col :span="11"> <a-month-picker style="width: 100px;" valueFormat="YYYY-MM" :format="monthFormat"  @change="onChangePicker" placeholder="选择月份" /></a-col>
<!--                      <a-range-picker v-model="searchForm.date" format="YYYY-MM-DD"  @change="changeRangeDate"/>-->
          </a-row>

        </a-col>

      </a-row>

      <a-row style="margin-top: 12px;">
        <a-table size="small" :columns="columns" :dataSource="items" rowKey="id" :loading="loading" :pagination="pagination"
          @change="tableChange">
          <div slot="action" slot-scope="value, item">
            <a-button-group size="small">
              <a-button size="small" @click="detial(item)">详情</a-button>
              <a-popconfirm title="确定作废吗?" @confirm="voidItem(item)">
                <a-button type="danger" :disabled="item.is_void">{{ item.is_void ? '已作废' : '作废'}}</a-button>
              </a-popconfirm>
            </a-button-group>
          </div>
        </a-table>
      </a-row>
    </a-card>
  </div>
</template>

<script>
  import { purchaseOrderList, purchaseOrdersVoid } from '@/api/purchasing'
  import {ChangePicker} from "@/utils/excel";

  export default {
    name: 'Warehouse',
    components: {
    },
    data() {
      return {
        monthFormat : 'YYYY-MM',

        columns: [
          {
            title: '序号',
            dataIndex: 'index',
            key: 'index',
            customRender: (value, item, index) => {
              return index + 1
            },
            width: 45
          },
          {
            title: '采购编号',
            dataIndex: 'number',
            sorter: true,
          },
          {
            title: '供应商',
            dataIndex: 'supplier_name',
          },
          {
            title: '经手人',
            dataIndex: 'handler_name',
          },
          {
            title: '发生日期',
            dataIndex: 'handle_time',
            width: 100
          },
          {
            title: '创建时间',
            dataIndex: 'create_time',
            width: 170
          },
          {
            title: '采购总数量',
            dataIndex: 'total_quantity',
            width: 120
          },
          {
            title: '采购总金额',
            dataIndex: 'total_amount',
            width: 120
          },
          {
            title: '付款金额',
            dataIndex: 'payment_amount',
            width: 120
          },
          {
            title: '其他费用',
            dataIndex: 'other_amount',
            width: 120
          },
          {
            title: '操作',
            dataIndex: 'action',
            scopedSlots: { customRender: 'action' },
            width: 147
          },
        ],
        searchForm: {page: 1, page_size: 50 }, //page: 1, page_size: 16
        pagination: { current: 1, total: 0, pageSize: 50 },
        loading: false,
        items: [],
        visible: false,
        targetItem: {},
        form: {},
      };
    },
    computed: {
    },
    methods: {
      onChangePicker(a,b){
        if (a == null) {
          this.searchForm.start_date =  a;
          this.searchForm.end_date = a;
          this.search();
          return;
        }
        const {first_day1,last_day1} = ChangePicker(a,b)
        this.searchForm.start_date =  first_day1;
        this.searchForm.end_date = last_day1;
        this.search();
      },


      onExport() {
        import("../../../utils/xlsx.js").then((excel) => {
          //tHeader 为导出后的表头
          let tHeader = [], filterVal = [], list = []

          tHeader = [ "采购编号", "商品名称", "供应商",          "经手人",         "录入时间",    "发生日期",    "采购总数量",        "采购总金额",    "付款金额",         "其他费用",     "备注", ];
          filterVal =[ "number", "goods_name", "supplier_name", "handler_name", "create_time","handle_time", "total_quantity", "total_amount", "payment_amount", "other_amount","remark", ];
          list = this.exportItems
          const data = this.formatJson(filterVal, list);
          excel.export_json_to_excel({
            header: tHeader,
            data,
            filename:`采购出库列表`+ this.getCurrentDate(),
            autoWidth: true,
            bookType: "xlsx",
          });
        });

      },

      getCurrentDate() {
        let today = new Date();
        let year = today.getFullYear();
        let month = (today.getMonth() + 1).toString().padStart(2, '0'); // 月份从0开始，需要+1，并且补0到两位数
        let day = today.getDate().toString().padStart(2, '0'); // 补0到两位数
        let formattedDate = `${year}${month}${day}`; // 拼接年月日
        return formattedDate;
      },
      formatJson(filterVal, jsonData) {
        return jsonData.map((v) =>
            filterVal.map((j) => {
              if (j === "timestamp") {
                return parseTime(v[j]);
              } else {
                return v[j];
              }
            })
        );
      },

      // changeRangeDate( param = this.defaultExtraParam){
      //   console.log(4444,param)
      //   // debugger
      //   this.searchForm.start_date=param + '-01'
      //   this.searchForm.end_date=param + '-31'
      //   this.search()
      // },

      initialize() {
        this.list();
      },
      list() {
        this.loading = true;
        purchaseOrderList(this.searchForm).then(data => {
          this.pagination.total = data.count;
          this.items = data.results;

          this.exportItems=[]
          this.items.forEach(item=>{
            const obj=item
            obj.total_amount=parseFloat(item.total_amount)
            obj.other_amount=parseFloat(item.other_amount)
            obj.payment_amount=parseFloat(item.payment_amount)
            obj.goods_name=item.purchase_goods_items[0].goods_name

            this.exportItems.push(obj)
          })

        }).finally(() => {
          this.loading = false;
        });
      },
      tableChange(pagination, filters, sorter) {
        this.searchForm.page = pagination.current;
        this.pagination.current = pagination.current;
        this.searchForm.ordering = `${sorter.order == 'descend' ? '-' : ''}${sorter.field}`;
        this.list();
      },

      search() {
        this.searchForm.page = 1;
        this.pagination.current = 1;
        this.list();
      },
      detial(item) {
        this.$router.push({ path: '/purchasing/purchase_record_detail', query: { id: item.id } });
      },
      voidItem(item) {
        purchaseOrdersVoid({ id: item.id }).then(() => {
          this.$message.success('作废成功');
          this.list();
        });
      },
    },
    mounted() {
      this.initialize();
    },
  }
</script>
